import React, { useRef, useState, useEffect } from 'react';
import {
  getVideoParts,
  getYoutubeVideoId,
  isYoutubeVideo,
} from '../../utils/videoUtility';
import { VideoMediaContainer } from './VideoMedia.styled';
import classNames from 'node_modules/classnames/index';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { useIntersectionObserver } from 'src/components/DesignSystem/Widgets2.0/ListPresentation/hooks/useIntersectionObserver';

const DEFAULT_VIDEO = 'https://www.youtube.com/watch?v=lKUQqPrRaN4';

const RawVideo = (props) => {
  const { mediaContent: { showMuteUnmuteOption = false, getVideoDuration } = {} } = props;
  const [isMuted, setIsMuted] = useState(true);
  const videoUrl = props?.mediaContent?.content[0]?.url;
  const videoUrlParts = getVideoParts(videoUrl);
  const isMobile = isMobileDevice();
  const videoRef = useRef();
  const [containerRef, isVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  useEffect(() => {
    const video = videoRef.current;
    if (video && showMuteUnmuteOption) {
      if (isVisible) {
        getVideoDuration?.(video.duration, isVisible);
        video.muted = isMuted;
        video.play().catch(() => {});
      } else {
        getVideoDuration?.(video.duration, isVisible);
        video.pause();
        video.muted = true;
        video.currentTime = 0;
      }
    }
  }, [isVisible]);

  function handleMuteUnMute() {
    setIsMuted((prev) => !prev);
  }

  return (
    <VideoMediaContainer ref={containerRef} key={videoUrl} className="video-container">
      <video
        key={videoUrl}
        ref={videoRef}
        autoPlay
        loop
        muted={isMuted}
        onError={(e) => (e.target.src = videoUrl)}
        playsInline
      >
        <source src={videoUrl} type={videoUrlParts?.mimeType} />
      </video>
      {showMuteUnmuteOption && (
        <div
          onClick={handleMuteUnMute}
          className={classNames(
            `tw-pointer wb-sprite tw-absolute ${isMobile ? 'tw-bottom-[10px] tw-right-[10px]' : 'tw-bottom-[16px] tw-right-[16px]'} tw-z-[4]`,
            isMuted ? 'unmute-lg' : 'mute-lg'
          )}
        ></div>
      )}
    </VideoMediaContainer>
  );
};

const YTVideo = (props) => {
  const videoUrl = props?.mediaContent?.content[0]?.url;
  const ytVideoId = getYoutubeVideoId(videoUrl);

  if (ytVideoId) {
    const urlParams = [];
    urlParams.push('modestbranding=1');
    urlParams.push('color=white');
    urlParams.push('playsinline=1');
    props.hideControls && urlParams.push('controls=0');
    props.autoplay && urlParams.push(`autoplay=1`);
    (props.muted || props.autoplay) && urlParams.push(`mute=1`);
    const embedUrl = `https://www.youtube.com/embed/${ytVideoId}?${urlParams.join('&')}`;
    return (
      <VideoMediaContainer key={videoUrl} className="video-container">
        <iframe key={videoUrl} src={embedUrl} title="YouTube"></iframe>
      </VideoMediaContainer>
    );
  }
  return null;
};

const VideoMedia = (props) => {
  if (props?.mediaContent?.content) {
    const videoUrlParts = getVideoParts(
      props?.mediaContent?.content[0]?.url || DEFAULT_VIDEO
    );

    if (isYoutubeVideo(videoUrlParts)) {
      return <YTVideo {...props} />;
    }
    return <RawVideo {...props} />;
  } else {
    return null;
  }
};

export default VideoMedia;
